<template>
  <div>
    <template v-if="listingProduct.length > 0">
      <el-main style="position: relative;">
        <el-row :gutter="20">
          <el-col
            v-for="product in listingProduct"
            :key="product"
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
          >
            <router-link :to="`/product/${product.productId}`">
              <ProductCard :productDetail="product" />
            </router-link>
          </el-col>
        </el-row>
      </el-main>
    </template>
    <template v-else>
      <div
        style="display: flex; flex-flow: column; align-items: center; justify-content: center; height: 100%;"
      >
        <div style="margin-bottom: 20px;">You have no listings yet.</div>
        <el-button class="custom-btn primary-btn" @click="uploadBtnOnClick">Upload</el-button>
      </div>
      <el-dialog
        v-model="payoutInfoWarningDialog"
        :center="true"
        custom-class="custom-dialog place-bid-dialog"
      >
        <div>
          <div class="main-header center-text">Before you create your first listing...</div>
          <div class="bid-input-wrapper">
            <div
              style="text-align: center;"
            >Please complete your preferred payment method for us to promptly credit your future earnings to you.</div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              class="custom-btn primary-btn"
              @click="$router.push('/profile/settings')"
            >Go To Settings</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <div class="floating-btn" @click="uploadBtnOnClick">
      <i class="el-icon-plus"></i>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import productServices from '@/services/product-service';
import ProductCard from '@/components/Product/ProductCard.vue';

export default {
  name: 'Listings',
  props: {
    profileDetail: Object,
  },
  setup(props) {
    const store = useStore();
    const listingProduct = ref([]);
    const gettingListingProduct = ref(true);
    const router = useRouter();
    const payoutInfoWarningDialog = ref(false);

    onMounted(async () => {
      gettingListingProduct.value = true;
      listingProduct.value = await productServices.getProductByUserId(store.state.auth.loginProfile.userId);
      gettingListingProduct.value = false;
    });

    const uploadBtnOnClick = () => {
      if (!props.profileDetail.payoutInfo) {
        payoutInfoWarningDialog.value = true;
        return;
      }
      router.push('/profile/uploadproduct');
    };

    return {
      listingProduct,
      gettingListingProduct,
      uploadBtnOnClick,
      payoutInfoWarningDialog,
    };
  },
  components: {
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.floating-btn {
  position: fixed;
  bottom: 100px;
  background: #ffffff;
  border-radius: 50%;
  right: 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 5px 0 #00000033, 0 2px 10px 0 #0000001a;
  cursor: pointer;

  [class*=' el-icon-'],
  [class^='el-icon-'] {
    font-size: 20px;
    font-weight: bold !important;
  }
}
</style>
