<template>
  <el-form
    class="custom-form"
    ref="accountForm"
    :model="accountFormModel"
    :rules="accountFormRules"
    label-position="top"
    label-width="100px"
  >
    <el-row :gutter="20">
      <el-col>
        <label class="custom-form-header form-header">Account Information</label>
      </el-col>
      <el-col :span="24" :md="12">
        <TextInput
          v-model="accountFormModel.firstName"
          formProps="firstName"
          formLabel="First Name"
        />
      </el-col>
      <el-col :span="24" :md="12">
        <TextInput v-model="accountFormModel.lastName" formProps="lastName" formLabel="Last Name" />
      </el-col>
      <el-col>
        <TextInput
          v-model="accountFormModel.emailAddress"
          formProps="emailAddress"
          formLabel="Email Address"
        />
      </el-col>
      <el-col>
        <el-form-item class="button-wrapper">
          <el-button class="custom-btn secondary-btn" @click="resetForm">Discard</el-button>
          <el-button
            class="custom-btn primary-btn"
            :disabled="submiting"
            :loading="submiting"
            @click="onSubmit"
          >Save</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { onMounted, ref } from 'vue';
import TextInput from '@/components/Share/TextInput.vue';
import authServices from '@/services/auth-service';
import profileServices from '@/services/profile-service';
import { ElMessage } from 'element-plus';

export default {
  components: {
    TextInput,
  },
  props: {
    profileDetail: {
      type: Object,
      required: true,
    },
    getProfile: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const userDetail = ref(null);
    const accountForm = ref(null);
    const accountFormModel = ref({
      firstName: '',
      lastName: '',
      emailAddress: '',
    });
    const accountFormRules = ref({
      firstName: [
        {
          required: true,
          message: 'Please enter your first name',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Please enter your last name',
        },
      ],
      emailAddress: [
        {
          required: true,
          message: 'Please enter your email address',
        },
      ],
    });
    const submiting = ref(false);

    const iniFunction = () => {
      authServices.getUserById(props.profileDetail.userId).then((data) => {
        accountFormModel.value = {
          firstName: data.firstName,
          lastName: data.lastName,
          emailAddress: data.emailAddress,
        };
        userDetail.value = {
          ...data,
        };
      });
    };

    onMounted(() => {
      iniFunction();
    });

    const onSubmit = () => {
      accountForm.value.validate((valid) => {
        if (valid) {
          const updatedUser = {
            ...accountForm.value.model,
            displayName: `${accountForm.value.model.firstName} ${accountForm.value.model.lastName}`,
          };

          submiting.value = true;
          authServices
            .updateUserById(props.profileDetail.userId, updatedUser)
            .then(async () => {
              const newProfile = {
                ...props.profileDetail,
                firstName: updatedUser.firstName,
                lastName: updatedUser.lastName,
              };
              const isCuccess = await profileServices.updateProfile(newProfile);
              if (isCuccess) {
                props.getProfile();
                iniFunction();
                ElMessage.success({
                  showClose: true,
                  message: 'Successfully update account information',
                });
              } else {
                ElMessage.error({
                  showClose: true,
                  message: 'Fail to update account information',
                });
              }
            })
            .finally(() => {
              submiting.value = false;
            });
        }
      });
    };

    const resetForm = () => {
      accountFormModel.value = {
        firstName: userDetail.value.firstName,
        lastName: userDetail.value.lastName,
        emailAddress: userDetail.value.emailAddress,
      };
    };

    return {
      accountForm,
      accountFormModel,
      accountFormRules,
      onSubmit,
      submiting,
      resetForm,
    };
  },
};
</script>
