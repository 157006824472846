<template>
  <div class="transaction-card">
    <el-row :gutter="20">
      <el-col>
        <div>{{ dayjs(transactionDetail.createdDate).format('DD/MM/YYYY hh:mm A') }}</div>
      </el-col>
      <el-col :xs="24" :md="15">
        <div v-if="productDetail">
          <div style="display: flex">
            <div>
              <el-image class="transaction-img" fit="cover" :src="productDetail.images[0].productImageUrl"></el-image>
            </div>
            <div style="padding-left: 10px; display: flex; flex-flow: column; width: 100%">
              <div style="display: flex">
                <div>
                  <div>{{ productDetail.name }}</div>
                  <div>{{ productDetail.series.license.name }}, {{ productDetail.scale }} Scale</div>
                </div>
                <div style="margin-left: 20px; font-size: 20px">
                  <router-link :to="`/product/${productDetail.productId}`">
                    <i class="fa fa-external-link" aria-hidden="true"></i>
                  </router-link>
                </div>
              </div>
              <div style="margin-top: auto; text-transform: uppercase; font-weight: bold">
                <div v-if="isBuyer">
                  <div v-if="transactionDetail.type === 'Offer_Payment'">Offer Accepted:</div>
                  <div v-else-if="transactionDetail.type === 'Auction_Payment'">Winning Bid:</div>
                  <div v-else>Purchased At:</div>
                </div>
                <div v-else>
                  <div v-if="transactionDetail.type === 'Offer_Payment'">Offer Accepted:</div>
                  <div v-else-if="transactionDetail.type === 'Auction_Payment'">Highest Bid:</div>
                  <div v-else>Sold At:</div>
                </div>
                <div>{{ `SGD$${transactionDetail.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}` }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :md="9">
        <div class="status-wrapper">
          <div style="font-weight: bold; text-transform: uppercase">
            <div>Status:</div>
            <div>{{ transactionDetail.status.replace('_', ' ').replace('_', ' ') }}</div>
          </div>
          <div class="detail-btn-wrapper">
            <el-button class="custom-btn primary-btn" @click="viewDetail">Details</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getAuthID } from '@/helpers';
import productServices from '@/services/product-service';
import dayjs from 'dayjs';

export default {
  props: {
    transactionDetail: {
      type: Object,
      required: true,
    },
    modelValue: String,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const productDetail = ref(null);
    const { userId } = getAuthID();
    const isBuyer = ref(false);

    onMounted(async () => {
      productDetail.value = await productServices.getProductById(props.transactionDetail.productId);
      isBuyer.value = props.transactionDetail.buyerUserId === userId;
    });

    const viewDetail = () => {
      emit('update:modelValue', props.transactionDetail.transactionId);
      router.push({
        path: '/profile/transactions',
        query: {
          id: props.transactionDetail.transactionId,
        },
      });
    };

    return {
      productDetail,
      dayjs,
      isBuyer,
      viewDetail,
    };
  },
};
</script>

<style lang="scss">
.transaction-card {
  border: 1px solid #00000030;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.transaction-img {
  height: 170px;
  width: 240px;
  display: block;
}

.status-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;

  .detail-btn-wrapper {
    margin-top: auto;
  }
}

@media screen and (max-width: 576px) {
  .transaction-img {
    height: 130px !important;
    width: 130px !important;
  }
}

@media screen and (max-width: 992px) {
  .status-wrapper {
    flex-flow: unset;
    justify-content: space-between;
    margin-top: 20px;

    .detail-btn-wrapper {
      margin-top: unset;
    }
  }
}
</style>

