<template>
  <div v-loading="transactionListLoading">
    <CustomTab v-if="transactionId === ''" v-model="activeTabName" :tabs="tabOptions" />
    <template v-if="transactionList.length > 0">
      <template v-if="transactionId === ''">
        <div style="padding: 20px 10px 0 10px; position: relative;">
          <el-row :gutter="20" v-if="!transactionListLoading">
            <el-col :span="24" v-for="transaction in transactionList" :key="transaction">
              <TransactionPreview v-model="transactionId" :transactionDetail="transaction" />
            </el-col>
          </el-row>
          <el-row :gutter="20" v-else>
            <el-col :span="24" v-for="index in 4" :key="index">
              <ProductCardLoader />
            </el-col>
          </el-row>

          <div style="text-align: right;">
            <el-pagination
              layout="prev, pager, next"
              :total="pagination.totalRecord"
              :page-size="pagination.itemPerPage"
              :current-page="pagination.currentPage + 1"
              @current-change="paginationCallback"
            ></el-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <TransactionDetail v-model="transactionId" />
      </template>
    </template>
    <template v-else>
      <el-empty description="No Records"></el-empty>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import transactionServices from '@/services/transaction-service';
import TransactionPreview from '@/components/Transaction/TransactionPreview.vue';
import ProductCardLoader from '@/components/Product/ProductCardLoader.vue';
import CustomTab from '@/components/CustomTab.vue';
import TransactionDetail from '@/components/Transaction/TransactionDetail.vue';

const defaultPagination = {
  itemPerPage: 4,
  totalRecord: 0,
  currentPage: 0,
};

export default {
  name: 'Transactions',
  components: {
    TransactionPreview,
    ProductCardLoader,
    CustomTab,
    TransactionDetail,
  },
  setup() {
    const route = useRoute();
    const transactionListLoading = ref(false);
    const transactionListOri = ref([]);
    const transactionList = ref([]);
    const activeTabName = ref('bought');
    const tabOptions = ref([
      {
        tabName: 'bought',
        tabLabel: 'Bought',
      },
      {
        tabName: 'sold',
        tabLabel: 'Sold',
      },
    ]);
    const transactionId = ref(route.query.id || '');
    const pagination = ref({
      ...defaultPagination,
    });

    const getUserSoldTransactions = async () => {
      const transactions = await transactionServices.getUserSoldTransactions();
      transactionListOri.value = transactions.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    };

    const getUserBoughtTransactions = async () => {
      const transactions = await transactionServices.getUserBoughtTransactions();
      transactionListOri.value = transactions.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    };

    const getTransactionList = async (paginationParams) => {
      transactionListLoading.value = true;
      if (activeTabName.value === 'bought') {
        await getUserBoughtTransactions();
      } else {
        await getUserSoldTransactions();
      }

      transactionList.value = transactionListOri.value.slice(
        paginationParams.itemPerPage * paginationParams.currentPage,
        paginationParams.itemPerPage * (paginationParams.currentPage + 1),
      );

      pagination.value = {
        itemPerPage: paginationParams.itemPerPage,
        totalRecord: transactionListOri.value.length,
        currentPage: paginationParams.currentPage,
      };

      transactionListLoading.value = false;
    };

    const paginationCallback = (page) => {
      const newPagination = {
        ...pagination.value,
        currentPage: page - 1,
      };
      getTransactionList({
        ...newPagination,
      });
    };

    onMounted(async () => {
      getTransactionList(pagination.value);
    });

    watch(
      () => activeTabName.value,
      () => {
        pagination.value = {
          ...defaultPagination,
        };
        getTransactionList(pagination.value);
      },
    );

    return {
      activeTabName,
      transactionListLoading,
      transactionList,
      tabOptions,
      transactionId,
      pagination,
      paginationCallback,
    };
  },
};
</script>
