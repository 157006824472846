<template>
  <el-main style="position: relative;" v-loading="gettingLikeProduct">
    <el-row :gutter="20">
      <el-col>
        <div  v-if="likedProducts.length > 0"
          style="display: flex; align-items: center; justify-content: flex-end; cursor: pointer;"
        >
          <div v-if="!isRemoveMode" @click="isRemoveMode = true;" style="color: #AC3131;">
            <i class="fa fa-trash" aria-hidden="true" style="padding-right: 8px;"></i>Remove
          </div>
          <div v-else style="display: flex;">
            <div @click="isRemoveMode = false;" style="padding-right: 15px;">
              <i class="fa fa-times" aria-hidden="true" style="padding-right: 8px;"></i>Cancel
            </div>
            <div @click="removeLikeProducts" style="color: #AC3131;">
              <i class="fa fa-trash" aria-hidden="true" style="padding-right: 8px;"></i>Confirm
            </div>
          </div>
        </div>
      </el-col>
      <el-col v-for="like in likedProducts" :key="like" :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
        <template v-if="!isRemoveMode">
          <router-link :to="`/product/${like.product.productId}`">
            <ProductCard :productDetail="like.product" />
          </router-link>
        </template>
        <template v-else>
          <div
            class="like-container"
            :class="selectedLikeProduct.includes(like.likeId) ? 'selected' : ''"
            @click="likeProductOnclick(like.likeId)"
          >
            <div class="checkbox"></div>
            <ProductCard :productDetail="like.product" />
          </div>
        </template>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { ref, onMounted } from 'vue';
import productServices from '@/services/product-service';
import ProductCard from '@/components/Product/ProductCard.vue';

export default {
  name: 'Likes',
  setup() {
    const likedProducts = ref([]);
    const gettingLikeProduct = ref(true);
    const isRemoveMode = ref(false);
    const selectedLikeProduct = ref([]);

    const getLikedProducts = async () => {
      gettingLikeProduct.value = true;
      likedProducts.value = await productServices.getLikedProducts();
      gettingLikeProduct.value = false;
    };

    onMounted(async () => {
      getLikedProducts();
    });

    const removeLikeProducts = async () => {
      isRemoveMode.value = false;
      const body = {
        likeIds: selectedLikeProduct.value,
      };
      await productServices.removeLikeProducts(body);
      getLikedProducts();
    };

    const likeProductOnclick = (likeId) => {
      if (selectedLikeProduct.value.includes(likeId)) {
        const index = selectedLikeProduct.value.indexOf(likeId);
        selectedLikeProduct.value.splice(index, 1);
      } else {
        selectedLikeProduct.value.push(likeId);
      }
    };

    return {
      likedProducts,
      gettingLikeProduct,
      isRemoveMode,
      removeLikeProducts,
      selectedLikeProduct,
      likeProductOnclick,
    };
  },
  components: {
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.like-container {
  transition: all 0.2s;
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  .checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    left: 20px;
    z-index: 2;
    border: 2px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected {
    background: #c4c4c4;
    outline: 2px solid rgb(134, 134, 134);

    .checkbox {
      &::after {
        content: '';
        background: #c4c4c4;
        width: 14px;
        height: 15px;
        border-radius: 4px;
        display: block;
      }
    }
  }
}
</style>
