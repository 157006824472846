const PRODUCT_FORM = {
  model: {
    name: '',
    salesType: 'Fixed',
    listingPrice: null,
    shippingFee: null,
    productImagesFiles: [],
    existingProductImages: [],
    characters: [],
    seriesId: '',
    scale: '',
    license: '',
    size: '',
    serialNumber: '',
    weight: '',
    distributor: '',
    description: '',
  },
  rules: {
    name: [
      {
        required: true,
        message: 'Please enter artowrk name',
      },
    ],
    listingPrice: [
      {
        required: true,
        message: 'Please enter price for listing',
      },
    ],
    shippingFee: [
      {
        required: true,
        message: 'Please enter shipping fee',
      },
    ],
    productImagesFiles: [
      {
        type: 'array',
        required: true,
        message: 'Please upload images',
      },
    ],
    characters: [
      {
        required: true,
        message: 'Please enter character name',
      },
    ],
    seriesId: [
      {
        required: true,
        message: 'Please select series',
      },
    ],
    scale: [
      {
        required: true,
        message: 'Please select scale',
      },
    ],
    license: [
      {
        required: true,
        message: 'Please select license',
      },
    ],
    size: [
      {
        required: true,
        message: 'Please enter size',
      },
    ],
    serialNumber: [
      {
        required: true,
        message: 'Please enter serial number',
      },
    ],
    weight: [
      {
        required: true,
        message: 'Please enter weight',
      },
    ],
    distributor: [
      {
        required: true,
        message: 'Please select distributor',
      },
    ],
    description: [
      {
        required: true,
        message: 'Please enter description',
      },
    ],
  },
};

export default PRODUCT_FORM;
