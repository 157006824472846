<template>
  <el-form
    class="custom-form"
    label-position="top"
    label-width="100px"
    :model="productFormModel"
    :rules="formRules"
    ref="productForm"
  >
    <div v-loading="gettingProductForm || submitingForm">
      <el-row :gutter="20">
        <el-col :span="24">
          <label class="custom-form-header form-header">Upload a new product or artwork</label>
        </el-col>
        <el-col :span="12" :xs="24">
          <TextInput v-model="productFormModel.name" formProps="name" formLabel="Artwork Name" />
        </el-col>
        <el-col :span="12" :xs="24">
          <el-row>
            <el-col>
              <SelectInput
                v-model="productFormModel.salesType"
                formProps="salesType"
                formLabel="Sale Type"
                :disabled="defaultFormData !== null"
              >
                <el-option label="Fixed Price" value="Fixed"></el-option>
                <el-option label="Auction" value="Auction"></el-option>
              </SelectInput>
            </el-col>
            <el-col v-if="productFormModel.salesType == 'Fixed'">
              <div class="sales-type-detail">
                <div class="dollar-sign">$</div>
                <div class="field-container">
                  <TextInput
                    v-model="productFormModel.listingPrice"
                    inputType="number"
                    formProps="listingPrice"
                    formLabel="Listing Price"
                  />
                </div>
              </div>
            </el-col>
            <el-col v-else>
              <div class="sales-type-detail">
                <div class="dollar-sign">$</div>
                <div class="field-container">
                  <TextInput
                    v-model="productFormModel.listingPrice"
                    inputType="number"
                    formProps="listingPrice"
                    formLabel="Listing/Starting Price"
                  />
                </div>
              </div>
              <div class="sales-type-detail">
                <div class="dollar-sign">$</div>
                <div class="field-container">
                  <TextInput
                    v-model="productFormModel.minAuctionIncrement"
                    inputType="number"
                    formProps="minAuctionIncrement"
                    formLabel="Minimun Price"
                  />
                </div>
              </div>
              <div class="sales-type-detail">
                <div class="dollar-sign" style="visibility: hidden;">$</div>
                <div class="field-container">
                  <Datepicker
                    popperClass="product-form-auction-end"
                    v-model="productFormModel.auctionEndDate"
                    formProps="auctionEndDate"
                    formLabel="Auction End Date"
                    inputType="datetime"
                    dateFormat="DD/MM/YYYY HH:mm:ss"
                    :disabledDate="disableDate"
                  />
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <MultipleImagesUpload
            :formIsDisabled="false"
            v-model:imgFiles="productFormModel.productImagesFiles"
            formProps="productImagesFiles"
            formLabel="Artwork Images"
            :isEdit="false"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <label class="custom-form-header form-sub-header">Product details</label>
        </el-col>
        <el-col :span="12" :xs="24">
          <SelectInput
            :onChangeFunc="licenseOnChange"
            v-model="productFormModel.license"
            formProps="license"
            formLabel="License"
          >
            <el-option
              v-for="license in licenses"
              :key="license.licenseId"
              :label="license.name"
              :value="license.licenseId"
            ></el-option>
          </SelectInput>
        </el-col>
        <el-col :span="12" :xs="24">
          <MultipleSelectInput
            v-model="productFormModel.characters"
            formProps="characters"
            formLabel="Characters"
          >
            <el-option
              v-for="character in characters"
              :key="character.characterId"
              :label="character.name"
              :value="character.characterId"
            ></el-option>
          </MultipleSelectInput>
        </el-col>
        <el-col :span="12" :xs="24">
          <SelectInput v-model="productFormModel.seriesId" formProps="seriesId" formLabel="Series">
            <el-option
              v-for="serie in series"
              :key="serie.seriesId"
              :label="serie.name"
              :value="serie.seriesId"
            ></el-option>
          </SelectInput>
        </el-col>
        <el-col :span="12" :xs="24">
          <SelectInput v-model="productFormModel.scale" formProps="scale" formLabel="Scale">
            <el-option
              v-for="scale in scales"
              :key="scale.value"
              :label="scale.name"
              :value="scale.value"
            ></el-option>
          </SelectInput>
        </el-col>

        <el-col :span="12" :xs="24">
          <TextInput
            v-model="productFormModel.size"
            formProps="size"
            formLabel="Size (HxLxB in cm)"
          />
        </el-col>
        <el-col :span="12" :xs="24">
          <TextInput
            v-model="productFormModel.serialNumber"
            formProps="serialNumber"
            formLabel="Serial Number"
          />
        </el-col>
        <el-col :span="12" :xs="24">
          <TextInput
            v-model="productFormModel.weight"
            formProps="weight"
            formLabel="Weight (in kg)"
          />
        </el-col>
        <el-col :span="12" :xs="24">
          <SelectInput
            v-model="productFormModel.distributor"
            formProps="distributor"
            formLabel="Distributor"
          >
            <el-option
              v-for="distributor in distributors"
              :key="distributor.value"
              :label="distributor.name"
              :value="distributor.value"
            ></el-option>
          </SelectInput>
        </el-col>
        <el-col :span="24">
          <TextArea
            v-model="productFormModel.description"
            formProps="description"
            formLabel="Description"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <label class="custom-form-header form-sub-header">Shipping details</label>
        </el-col>
        <el-col :span="12" :xs="24">
          <TextInput
            v-model="productFormModel.shippingFee"
            inputType="number"
            formProps="shippingFee"
            formLabel="Shipping Fee"
          />
        </el-col>
      </el-row>
      <el-form-item class="button-wrapper">
        <el-button class="custom-btn secondary-btn" @click="resetForm($refs.productForm)">Discard</el-button>
        <el-button class="custom-btn primary-btn" @click="onSubmit($refs.productForm)">Submit</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { getUrlParams } from '@/helpers';
import { CONFIGURATION_NAMES } from '@/common/constants';
import configurationSevices from '@/services/configuration-service';
import productServices from '@/services/product-service';
import TextInput from '@/components/Share/TextInput.vue';
import SelectInput from '@/components/Share/SelectInput.vue';
import MultipleSelectInput from '@/components/Share/MultipleSelectInput.vue';
import TextArea from '@/components/Share/TextArea.vue';
import Datepicker from '@/components/Share/DateInput.vue';
import MultipleImagesUpload from '@/components/Share/MultipleImagesUpload.vue';
import PRODUCT_FORM from './constants';

export default {
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    resetForm: {
      type: Function,
      required: true,
    },
    submitingForm: {
      type: Boolean,
      required: true,
    },
    defaultFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    TextInput,
    SelectInput,
    MultipleSelectInput,
    TextArea,
    MultipleImagesUpload,
    Datepicker,
  },
  setup(props) {
    const productFormModel = ref(
      props.defaultFormData
        ? {
          ...props.defaultFormData,
        }
        : {
          ...PRODUCT_FORM.model,
        },
    );
    const formRules = ref({
      ...PRODUCT_FORM.rules,
    });
    const disableDate = (time) => time.getTime() < Date.now();
    const series = ref([]);
    const characters = ref([]);
    const scales = ref([]);
    const licenses = ref([]);
    const distributors = ref([]);
    const gettingProductForm = ref(true);
    const editProductId = getUrlParams('editid');

    const getLicenseAndCharacter = async () => {
      await Promise.all([
        productServices.retrieveCharactersByLicenseId(productFormModel.value.license),
        productServices.retrieveSeriesByLicenseId(productFormModel.value.license),
      ])
        .then((result) => {
          characters.value = [...result[0]];
          series.value = [...result[1]];
        })
        .catch(() => {});
    };

    onMounted(async () => {
      await Promise.all([
        productServices.retrieveAllLicenses(),
        configurationSevices.getConfigurationByName(CONFIGURATION_NAMES.productScale),
        configurationSevices.getConfigurationByName(CONFIGURATION_NAMES.productDistributors),
      ])
        .then((result) => {
          licenses.value = [...result[0]];
          scales.value = [...result[1][0].configurations];
          distributors.value = [...result[2][0].configurations];
        })
        .catch(() => {});

      if (editProductId) {
        await getLicenseAndCharacter();
      }

      gettingProductForm.value = false;
    });

    const licenseOnChange = async () => {
      gettingProductForm.value = true;

      productFormModel.value.seriesId = '';
      productFormModel.value.characters = [];
      await getLicenseAndCharacter();

      gettingProductForm.value = false;
    };

    watch(
      () => productFormModel.value.salesType,
      (newVal) => {
        if (newVal !== 'Fixed') {
          productFormModel.value = {
            ...productFormModel.value,
            minAuctionIncrement: null,
            auctionEndDate: null,
            listingPrice: null,
          };

          formRules.value = {
            ...formRules.value,
            auctionEndDate: [
              {
                required: true,
                message: 'Please select auction end date',
              },
            ],
            minAuctionIncrement: [
              {
                required: true,
                message: 'Please enter minimum increment',
              },
            ],
          };
        } else {
          delete productFormModel.value.auctionEndDate;
          delete productFormModel.value.minAuctionIncrement;
          delete formRules.value.auctionEndDate;
          delete formRules.value.minAuctionIncrement;
        }
      },
    );

    return {
      productFormModel,
      formRules,
      disableDate,
      gettingProductForm,
      series,
      characters,
      scales,
      licenses,
      distributors,
      licenseOnChange,
    };
  },
};
</script>
