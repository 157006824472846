<template>
  <div>
    <template v-if="profileReviews">
      <el-main style="position: relative;">
        <el-row :gutter="20">
          <el-col :span="24">
            <div style="display: flex; align-items: center; padding-bottom: 20px; flex-wrap: wrap;">
              <el-rate
                v-model="profileReviews.profileScore"
                disabled
                show-score
                text-color="#000000"
                score-template="{value} / 5"
                class="profile-score"
              ></el-rate>
              <div>{{ `(${profileReviews.reviews.length} reviews)` }}</div>
            </div>
          </el-col>
          <el-col v-for="review in profileReviews.reviews" :key="review" :span="24">
            <ReviewCard :reviewDetail="review" />
          </el-col>
        </el-row>
      </el-main>
    </template>
    <template v-if="profileReviews && profileReviews.reviews.length === 0">
      <el-empty description="No Records"></el-empty>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import profileServices from '@/services/profile-service';
import ReviewCard from '@/components/Review/ReviewCard.vue';

export default {
  name: 'Reviews',
  props: {
    profileDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const profileReviews = ref(null);
    const gettingReviews = ref(false);

    onMounted(async () => {
      gettingReviews.value = true;
      profileReviews.value = await profileServices.getProfileReviews(props.profileDetail.profileId);
      gettingReviews.value = false;
    });

    return {
      profileReviews,
      gettingReviews,
    };
  },
  components: {
    ReviewCard,
  },
};
</script>

<style lang="scss">
.profile-score {
  display: flex;
  align-items: center;
  padding-right: 20px;
  .el-rate__icon {
    font-size: 30px;
  }

  .el-rate__text {
    font-weight: bold;
    font-size: 20px !important;
  }
}
</style>
