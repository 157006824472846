<template>
  <el-form
    class="custom-form"
    ref="passwordForm"
    :model="passwordFormModel"
    :rules="passwordFormRules"
    label-position="top"
    label-width="100px"
  >
    <el-row :gutter="20">
      <el-col>
        <label class="custom-form-header form-header">Change Password</label>
      </el-col>
      <el-col>
        <TextInput
          v-model="passwordFormModel.newPassword"
          formProps="newPassword"
          formLabel="New Password"
          inputType="password"
        />
      </el-col>
      <el-col>
        <TextInput
          v-model="passwordFormModel.confirmNewPassword"
          formProps="confirmNewPassword"
          formLabel="Confirm New Password"
          inputType="password"
        />
      </el-col>
      <el-col>
        <el-form-item class="button-wrapper">
          <el-button class="custom-btn secondary-btn" @click="passwordForm.resetFields()">Discard</el-button>
          <el-button
            class="custom-btn primary-btn"
            :disabled="submiting"
            :loading="submiting"
            @click="onSubmit"
          >Save</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { ref } from 'vue';
import TextInput from '@/components/Share/TextInput.vue';
import authServices from '@/services/auth-service';
import { ElMessage } from 'element-plus';
import { getAuthID } from '@/helpers';

export default {
  components: {
    TextInput,
  },
  setup() {
    const passwordForm = ref(null);
    const passwordFormModel = ref({
      newPassword: '',
      confirmNewPassword: '',
    });
    const passwordFormRules = ref({
      newPassword: [
        {
          required: true,
          message: 'Please enter password',
        },
        {
          min: 6,
          message: 'Password must be at least 6 character',
        },
      ],
      confirmNewPassword: [
        {
          required: true,
          message: 'Please enter password',
        },
        {
          min: 6,
          message: 'Password must be at least 6 character',
        },
        {
          validator: (rule, value, callback) => {
            if (value !== passwordFormModel.value.newPassword) {
              callback(new Error("Password doesn't match"));
            } else {
              callback();
            }
          },
        },
      ],
    });
    const submiting = ref(false);

    const onSubmit = () => {
      passwordForm.value.validate((valid) => {
        if (valid) {
          const updatedPassowrd = {
            newSecret: passwordForm.value.model.newPassword,
          };

          submiting.value = true;
          authServices
            .updateUserPassword(getAuthID().userId, updatedPassowrd)
            .then(() => {
              passwordForm.value.resetFields();
              ElMessage.success({
                showClose: true,
                message: 'Successfully udpate password',
              });
            })
            .finally(() => {
              submiting.value = false;
            });
        }
      });
    };

    return {
      passwordForm,
      passwordFormModel,
      passwordFormRules,
      onSubmit,
      submiting,
    };
  },
};
</script>
