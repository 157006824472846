<template>
  <el-form
    class="custom-form"
    :model="payoutFormModel"
    :rules="payoutFormRules"
    label-position="top"
    label-width="100px"
    ref="payoutForm"
  >
    <el-row :gutter="20">
      <el-col>
        <label class="custom-form-header form-header">Earnings Payment Method</label>
      </el-col>
      <el-col>
        <div class="payout-type">
          <el-button
            class="custom-btn secondary-btn"
            :class="selectedPayoutType === 'paynow' ? 'selected' : ''"
            :disabled="paynowRequiredFields.length === 0"
            @click="payoutTypeOnChange('paynow')"
          >Paynow</el-button>
          <el-button
            class="custom-btn secondary-btn"
            :class="selectedPayoutType === 'bank' ? 'selected' : ''"
            :disabled="payoutBanks.length === 0"
            @click="payoutTypeOnChange('bank')"
          >Bank Transfer</el-button>
        </div>
      </el-col>
      <template v-if="selectedPayoutType === 'paynow'">
        <el-col>
          <SelectInput
            v-model="payoutFormModel.identificationType"
            formProps="identificationType"
            formLabel="Pay To"
          >
            <el-option label="Phone Number" value="M"></el-option>
            <el-option label="NRIC" value="N"></el-option>
            <el-option label="UEN" value="U"></el-option>
          </SelectInput>
        </el-col>
        <el-col>
          <TextInput
            v-model="payoutFormModel.identificationValue"
            formProps="identificationValue"
            formLabel="Paynow Number"
          />
        </el-col>
      </template>
      <template v-if="selectedPayoutType === 'bank'">
        <el-col>
          <SelectInput
            v-model="payoutFormModel.payoutMethod"
            formProps="payoutMethod"
            formLabel="Bank"
          >
            <el-option
              v-for="bank in payoutBanks"
              :key="bank.name"
              :label="bank.name"
              :value="bank.payoutMethodType"
              @click="bankOnChange(bank)"
            ></el-option>
          </SelectInput>
        </el-col>
        <el-col>
          <TextInput
            v-model="payoutFormModel.accountNumber"
            formProps="accountNumber"
            formLabel="Account Number"
          />
        </el-col>
      </template>
      <el-col v-if="selectedPayoutType !== ''">
        <el-form-item class="button-wrapper">
          <el-button class="custom-btn secondary-btn" @click="resetForm">Discard</el-button>
          <el-button
            class="custom-btn primary-btn"
            @click="onSubmit"
            :loading="submitingForm"
            :disabled="submitingForm"
          >Save</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { ref, onMounted } from 'vue';
import transactionServices from '@/services/transaction-service';
import SelectInput from '@/components/Share/SelectInput.vue';
import TextInput from '@/components/Share/TextInput.vue';
import profileServices from '@/services/profile-service';
import { ElMessage } from 'element-plus';

export default {
  components: {
    SelectInput,
    TextInput,
  },
  props: {
    profileDetail: {
      type: Object,
      required: true,
    },
    getProfile: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const haveExistingPayout = props.profileDetail.payoutInfo ?? false;
    const paynowName = 'sg_paynow_bank';
    const payoutBanks = ref([]);
    const selectedPayoutType = ref('');
    const paynowRequiredFields = ref([]);
    const bankRequiredFields = ref([]);
    const payoutForm = ref(null);
    const paynowForm = {
      identificationType: '',
      identificationValue: '',
    };
    const bankForm = {
      accountNumber: '',
    };
    const payoutFormModel = ref({
      payoutMethod: '',
    });
    const defaultRules = {
      payoutMethod: [
        {
          required: true,
          message: 'Please select a payout method',
        },
      ],
    };
    const payoutFormRules = ref({
      ...defaultRules,
    });
    const submitingForm = ref(false);

    const constructPaynowForm = () => {
      const indentificationTypeFieldInfo = paynowRequiredFields.value.find((x) => x.name === 'identification_type');
      const indentificationValueFieldInfo = paynowRequiredFields.value.find((x) => x.name === 'identification_value');

      payoutFormModel.value = {
        ...paynowForm,
        payoutMethod: paynowName,
      };

      payoutFormRules.value = {
        ...defaultRules,
        identificationType: [
          {
            required: true,
            message: 'Please select a your paynow type',
          },
          {
            pattern: indentificationTypeFieldInfo.regex,
            message: 'Invalid paynow type',
          },
        ],
        identificationValue: [
          {
            required: true,
            message: 'Please enter your paynow number',
          },
          {
            pattern: indentificationValueFieldInfo.regex,
            message: 'Invalid paynow number',
          },
        ],
      };
    };

    const constructBankForm = () => {
      payoutFormRules.value = {
        ...defaultRules,
      };

      if (bankRequiredFields.value.length === 0) {
        payoutFormModel.value = {
          ...bankForm,
          payoutMethod: '',
        };
      } else {
        const accountNumberFieldInfo = bankRequiredFields.value.find((x) => x.name === 'account_number');

        payoutFormRules.value = {
          ...payoutFormRules.value,
          accountNumber: [
            {
              required: true,
              message: 'Please enter your account number',
            },
            {
              pattern: accountNumberFieldInfo.regex,
              message: 'Invalid format',
            },
          ],
        };
      }
    };

    const reteiveBankList = async () => {
      transactionServices.retrievePayoutMethods().then((data) => {
        payoutBanks.value = data.filter((x) => !x.payoutMethodType.includes(paynowName));
      });
    };

    const retrieveRequiredFields = async (payoutMethod) => transactionServices.retrievePayoutRequiredField(payoutMethod);

    const bankOnChange = (bank) => {
      retrieveRequiredFields(bank.payoutMethodType).then((data) => {
        bankRequiredFields.value = [...data.beneficiaryRequiredFields];
        constructBankForm();
      });
    };

    const payoutTypeOnChange = (payoutType) => {
      selectedPayoutType.value = payoutType;
      if (payoutType === 'paynow') {
        bankRequiredFields.value = [];
        constructPaynowForm();
      } else {
        constructBankForm();
      }
    };

    const constructExistingPayoutInfo = () => {
      selectedPayoutType.value = props.profileDetail.payoutInfo.payoutMethod === paynowName ? 'paynow' : 'bank';
      payoutFormModel.value = {
        ...props.profileDetail.payoutInfo,
      };

      if (selectedPayoutType.value === 'bank') {
        retrieveRequiredFields(payoutFormModel.value.payoutMethod).then((data) => {
          bankRequiredFields.value = [...data.beneficiaryRequiredFields];
          constructBankForm();
        });
      }
    };

    onMounted(async () => {
      await Promise.all([reteiveBankList(), retrieveRequiredFields(paynowName)]).then((result) => {
        paynowRequiredFields.value = [...result[1].beneficiaryRequiredFields];
      });

      if (haveExistingPayout) {
        constructExistingPayoutInfo();
      }
    });

    const onSubmit = () => {
      payoutForm.value.validate(async (valid) => {
        if (valid) {
          const profileDetail = {
            ...props.profileDetail,
            payoutInfo: {
              ...payoutForm.value.model,
            },
          };
          submitingForm.value = true;
          await profileServices
            .updateProfile(profileDetail)
            .then(
              () => {
                ElMessage.success({
                  showClose: true,
                  message: 'Successfully update earnings payment methods',
                });
                props.getProfile();
              },
              () => {
                ElMessage.error({
                  showClose: true,
                  message: 'Fail to update earnings payment methods',
                });
              },
            )
            .finally(() => {
              submitingForm.value = false;
            });
        }
      });
    };

    const resetForm = () => {
      payoutForm.value.resetFields();
      if (haveExistingPayout) {
        constructExistingPayoutInfo();
      } else {
        payoutForm.value.resetFields();
      }
    };

    return {
      payoutBanks,
      payoutForm,
      selectedPayoutType,
      bankOnChange,
      paynowRequiredFields,
      bankRequiredFields,
      payoutFormModel,
      payoutFormRules,
      payoutTypeOnChange,
      onSubmit,
      submitingForm,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.payout-type {
  padding: 20px 0;

  button.selected {
    outline: 5px solid #31ac62;
    border-color: white;
  }
}
</style>
