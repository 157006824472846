<template>
  <el-main>
    <el-row :gutter="20">
      <el-col>
        <AccountInformationForm :profileDetail="$attrs.profileDetail" :getProfile="$attrs.getProfile" />
      </el-col>
      <el-col>
        <UpdatePasswordForm />
      </el-col>
      <el-col>
        <PayoutForm :profileDetail="$attrs.profileDetail" :getProfile="$attrs.getProfile" />
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import AccountInformationForm from '@/components/Setting/AccountInformationForm.vue';
import PayoutForm from '@/components/Setting/PayoutForm.vue';
import UpdatePasswordForm from '@/components/Setting/UpdatePasswordForm.vue';

export default {
  components: {
    PayoutForm,
    AccountInformationForm,
    UpdatePasswordForm,
  },
};
</script>
