<template>
  <div style="padding: 0 10px">
    <div @click="backToList" class="back-button">Back to transactions</div>
    <el-row :gutter="20" v-if="Object.keys(transactionDetail).length > 0 && Object.keys(transactionProduct).length > 0">
      <el-col :xs="24" :md="15">
        <div>
          <div style="display: flex">
            <div>
              <el-image class="transaction-img" fit="cover" :src="transactionProduct.images[0].productImageUrl"></el-image>
            </div>
            <div style="padding-left: 10px; display: flex; flex-flow: column; width: 100%">
              <div style="display: flex">
                <div>
                  <div>{{ transactionProduct.name }}</div>
                  <div>{{ productLicense }} {{ transactionProduct.scale }} Scale</div>
                </div>
                <div style="margin-left: 20px; font-size: 20px">
                  <router-link :to="`/product/${transactionProduct.productId}`">
                    <i class="fa fa-external-link" aria-hidden="true"></i>
                  </router-link>
                </div>
              </div>
              <div style="margin-top: auto; font-weight: bold">
                <div v-if="isBuyer" style="text-transform: uppercase">
                  <div v-if="transactionDetail.type === 'Offer_Payment'">Offer Accepted:</div>
                  <div v-else-if="transactionDetail.type === 'Auction_Payment'">Winning Bid:</div>
                  <div v-else>Purchased At:</div>
                </div>
                <div v-else style="text-transform: uppercase">
                  <div v-if="transactionDetail.type === 'Offer_Payment'">Offer Accepted:</div>
                  <div v-else-if="transactionDetail.type === 'Auction_Payment'">Highest Bid:</div>
                  <div v-else>Sold At:</div>
                </div>
                <div>{{ `SGD$${transactionDetail.price.toLocaleString(undefined, { minimumFractionDigits: 2 })} (incl. shipping fee)` }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :md="9">
        <div class="status-wrapper">
          <div>
            <div style="font-weight: bold; text-transform: uppercase">{{ isBuyer ? 'Bought' : 'Sold' }} On:</div>
            <div>{{ dayjs(transactionDetail.createdDate).format('DD/MM/YYYY hh:mm A') }}</div>
          </div>
          <div class="detail-btn-wrapper" v-if="isBuyer">
            <div style="font-weight: bold; text-transform: uppercase">Bought From:</div>
            <div>
              <!-- {{ sellerUsername }} -->
              <Username :profileDetail="sellerProfile" />
            </div>
            <div
              v-if="transactionDetail.status.toLowerCase() === 'item_received' && isAbleToReview"
              class="fs-12"
              style="cursor: pointer"
              @click="reviewDialog = true"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
              Write a review
            </div>
          </div>
          <div class="detail-btn-wrapper" v-else>
            <div style="font-weight: bold; text-transform: uppercase">Sold To:</div>
            <div>
              <!-- {{ buyerUsername }} -->
              <Username :profileDetail="buyerProfile" />
            </div>
            <div class="fs-12" style="cursor: pointer" @click="viewDeliverAddressDialog = true">View Delivery Address</div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div style="padding-top: 30px">
          <section v-if="isBuyer" :style="{ padding: '20px 0 40px 0', textAlign: isMobileView ? 'center' : 'left' }">
            <el-button v-if="transactionDetail.status.toLowerCase() === 'pending_payment'" class="custom-btn secondary-btn" @click="makePayment"
              >Make Payment</el-button
            >
            <el-button
              v-if="transactionDetail.status.toLowerCase() === 'item_ship_out' && isShowButton"
              class="custom-btn secondary-btn"
              @click="orderReceivedDialogVisible = true"
              >Order Received</el-button
            >
            <p v-if="transactionDetail.status.toLowerCase() === 'pending_payment'">Click the Make Payment button to complete the transaction.</p>
          </section>

          <section v-if="!isBuyer" :style="{ padding: '20px 0 40px 0', textAlign: isMobileView ? 'center' : 'left' }">
            <el-button
              v-if="transactionDetail.status.toLowerCase() === 'completed_payment' && isShowButton"
              class="custom-btn secondary-btn"
              @click="updateStatusDialogVisible = true"
              >Update Status</el-button
            >
          </section>

          <el-timeline class="transaction-event-timeline">
            <el-timeline-item
              v-for="(event, index) in transactionDetail.transactionEvents"
              :key="index"
              :timestamp="dayjs(event.createdDate).format('DD/MM/YYYY hh:mm A')"
              placement="top"
              :color="index === 0 ? '#31AC62' : ''"
              size="large"
            >
              <div class="transaction-event" :class="index === 0 ? 'current' : ''">
                {{ event.event.replace('buyer', buyerUsername).replace('seller', sellerUsername) }}
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="updateStatusDialogVisible" :center="true" custom-class="custom-dialog place-bid-dialog">
    <div>
      <div class="main-header center-text">Shipping Update</div>
      <el-form
        class="custom-form"
        label-position="top"
        label-width="100px"
        :model="updateStatusModel"
        :rules="updateStatusRules"
        ref="updateStatusForm"
        @keyup.enter="updateShippingStatus"
        @submit.prevent
      >
        <Datepicker
          popperClass="product-form-auction-end"
          v-model="updateStatusModel.dateShipped"
          formProps="dateShipped"
          formLabel="Date Shipped"
          inputType="date"
          dateFormat="DD/MM/YYYY"
        />

        <TextInput v-model="updateStatusModel.courier" formProps="courier" formLabel="Courier" />
        <TextInput v-model="updateStatusModel.trackingNumber" formProps="trackingNumber" formLabel="Tracking Number" />
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="updateStatusDialogVisible = false">Cancel</el-button>
        <el-button class="custom-btn primary-btn accept-btn" @click="updateShippingStatus">Submit</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="orderReceivedDialogVisible" :center="true" custom-class="custom-dialog place-bid-dialog">
    <div>
      <div class="main-header center-text">Order Received</div>
      <p style="word-break: break-word; text-align: center">
        Once you confirm you have received your order, payment will be released to the seller and the order will be marked as completed. Please raise
        any disputes before you confirm your order.
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="orderReceivedDialogVisible = false">Cancel</el-button>
        <el-button class="custom-btn primary-btn accept-btn" @click="orderReceivedBtnClick">Confirm</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="viewDeliverAddressDialog" :center="true" custom-class="custom-dialog place-bid-dialog">
    <div>
      <div class="main-header center-text">Buyer Address</div>
      <article style="padding-left: 15px; padding-top: 10px">
        <div>{{ transactionDetail.name }}</div>
        <div>{{ `(+65) ${transactionDetail.phoneNumber}` }}</div>
        <div>{{ transactionDetail.address1 }}</div>
        <div>{{ transactionDetail.address2 }}</div>
        <div>{{ `${transactionDetail.country} ${transactionDetail.postalCode}` }}</div>
      </article>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="viewDeliverAddressDialog = false">Close</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="reviewDialog" :center="true" custom-class="custom-dialog place-bid-dialog">
    <div>
      <div class="main-header center-text">Write a review for</div>
      <div style="display: flex; align-items: center; justify-content: center; padding-top: 10px">
        <div class="profile-img-container">
          <img :src="sellerProfile.displayPhotoFilePath ?? defaultProfilePic" />
        </div>
        <div>{{ sellerProfile.userName }}</div>
      </div>

      <el-form class="custom-form" :model="reviewModel" :rules="reviewRule" ref="reviewForm">
        <el-form-item prop="rate">
          <div class="sub-header">Rating</div>
          <el-rate v-model="reviewModel.rate" allow-half></el-rate>
        </el-form-item>
        <div class="sub-header">Write a review</div>
        <el-form-item prop="review">
          <el-input :rows="5" type="textarea" v-model="reviewModel.review"></el-input>
        </el-form-item>
      </el-form>

      <!-- <div class="sub-header">Rating</div>
      <el-rate v-model="userRate"></el-rate>
      <div class="sub-header">Write a review</div>
      <TextArea v-model="review" formProps="review" formLabel="Description" />-->
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="custom-btn secondary-btn" @click="reviewDialog = false">Close</el-button>
        <el-button class="custom-btn primary-btn" :loading="submittingReview" :disabled="submittingReview" @click="submitReview">Submit</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import transactionServices from '@/services/transaction-service';
import { getAuthID } from '@/helpers';
import dayjs from 'dayjs';
import profileServices from '@/services/profile-service';
import productServices from '@/services/product-service';
import TextInput from '@/components/Share/TextInput.vue';
import Datepicker from '@/components/Share/DateInput.vue';
import { ElMessage } from 'element-plus';
import Username from '@/components/Profile/Username.vue';

export default {
  props: {
    modelValue: String,
  },
  components: {
    TextInput,
    Datepicker,
    Username,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const transactionDetail = ref({});
    const transactionProduct = ref({});
    const { userId } = getAuthID();
    const isBuyer = ref(false);
    const sellerProfile = ref(null);
    const sellerUsername = ref('');
    const buyerProfile = ref(null);
    const buyerUsername = ref('');
    const store = useStore();
    const isMobileView = computed(() => store.state.layout.isMobileView);
    const isAbleToReview = ref(false);
    const isShowButton = ref(true);

    const productLicense = computed(() => {
      if (transactionProduct.value.characters.length === 0) {
        return '';
      }
      return `${transactionProduct.value.characters[0].license.name}, `;
    });

    onMounted(async () => {
      transactionDetail.value = await transactionServices.getTransactionDetail(props.modelValue);
      if (!(transactionDetail.value.sellerUserId === userId || transactionDetail.value.buyerUserId === userId)) {
        router.push('/');
      }

      // const product = await productServices.getProductById(transactionDetail.value.productId);
      // transactionProduct.value = product;
      // isBuyer.value = transactionDetail.value.buyerUserId === userId;
      // buyerProfile.value = await profileServices.getProfilebyUserId(transactionDetail.value.buyerUserId);
      // buyerUsername.value = isBuyer.value ? 'You' : buyerProfile.value.userName;
      // sellerProfile.value = await profileServices.getProfilebyUserId(transactionDetail.value.sellerUserId);
      // sellerUsername.value = !isBuyer.value ? 'You' : sellerProfile.value.userName;

      await Promise.all([
        productServices.getProductById(transactionDetail.value.productId),
        profileServices.getProfilebyUserId(transactionDetail.value.buyerUserId),
        profileServices.getProfilebyUserId(transactionDetail.value.sellerUserId),
        profileServices.checkUserAbleToReview(transactionDetail.value.transactionId),
      ]).then((result) => {
        transactionProduct.value = {
          ...result[0],
        };
        isBuyer.value = transactionDetail.value.buyerUserId === userId;

        buyerProfile.value = {
          ...result[1],
        };
        buyerUsername.value = isBuyer.value ? 'You' : buyerProfile.value.userName;

        sellerProfile.value = {
          ...result[2],
        };
        sellerUsername.value = !isBuyer.value ? 'You' : sellerProfile.value.userName;

        isAbleToReview.value = result.pop();
      });
    });

    const backToList = () => {
      emit('update:modelValue', '');
      router.push({
        path: '/profile/transactions',
      });
    };

    const makePayment = () => {
      if (transactionDetail.value.paymentUrl !== '' && transactionDetail.value.paymentUrl !== null) {
        window.location.replace(transactionDetail.value.paymentUrl);
      } else {
        router.push(`/payment/${transactionProduct.value.productId}/${transactionDetail.value.transactionId}`);
      }
    };

    const updateStatusForm = ref(null);
    const updateStatusDialogVisible = ref(false);
    const updateStatusModel = ref({
      dateShipped: null,
      courier: null,
      trackingNumber: null,
    });

    const updateStatusRules = ref({
      dateShipped: [
        {
          required: true,
          message: 'Please enter shipping date',
        },
      ],
      courier: [
        {
          required: true,
          message: 'Please enter courier',
        },
      ],
      trackingNumber: [
        {
          required: true,
          message: 'Please enter tracking number',
        },
      ],
    });

    const updateShippingStatus = () => {
      updateStatusForm.value.validate(async (valid) => {
        if (valid) {
          const shippingDetail = {
            ...updateStatusModel.value,
            transactionId: transactionDetail.value.transactionId,
          };
          await transactionServices.updateTransactionShipping(shippingDetail);
          updateStatusDialogVisible.value = false;
          isShowButton.value = false;
        }
      });
    };

    const orderReceivedDialogVisible = ref(false);
    const orderReceivedBtnClick = async () => {
      await transactionServices.updateOrderReceived(transactionDetail.value.transactionId);
      orderReceivedDialogVisible.value = false;
      isShowButton.value = false;
    };

    const viewDeliverAddressDialog = ref(false);
    const reviewDialog = ref(false);

    const defaultProfilePic = process.env.VUE_APP_DEFAULT_PIC_URL;
    const reviewForm = ref(null);
    const reviewModel = reactive({
      rate: null,
      review: null,
    });

    const reviewRule = reactive({
      rate: [
        {
          validator: (rule, value, callback) => {
            if (value <= 0) {
              callback(new Error('Rate is required'));
            } else {
              callback();
            }
          },
        },
      ],
      review: [
        {
          required: true,
          message: 'Please enter review',
          trigger: ['blur', 'change'],
        },
      ],
    });

    const submittingReview = ref(false);
    const submitReview = () => {
      reviewForm.value.validate((valid) => {
        if (valid) {
          const reviewDetail = {
            profileId: isBuyer.value ? sellerProfile.value.profileId : buyerProfile.value.profileId,
            score: reviewModel.rate,
            comment: reviewModel.review,
            productId: transactionDetail.value.productId,
            transactionId: transactionDetail.value.transactionId,
          };
          submittingReview.value = true;
          profileServices
            .addReview(reviewDetail)
            .then(
              () => {
                ElMessage.success({
                  showClose: true,
                  message: 'Submit review successfully',
                });
              },
              (error) => {
                ElMessage.error({
                  showClose: true,
                  message: error,
                });
              }
            )
            .finally(async () => {
              reviewForm.value.resetFields();
              reviewDialog.value = false;
              submittingReview.value = false;
              isAbleToReview.value = await profileServices.checkUserAbleToReview(transactionDetail.value.transactionId);
            });
        }
      });
    };

    return {
      backToList,
      transactionDetail,
      transactionProduct,
      dayjs,
      isBuyer,
      sellerProfile,
      sellerUsername,
      buyerProfile,
      buyerUsername,
      isMobileView,
      makePayment,
      updateStatusForm,
      updateStatusDialogVisible,
      updateStatusModel,
      updateStatusRules,
      updateShippingStatus,
      orderReceivedDialogVisible,
      orderReceivedBtnClick,
      viewDeliverAddressDialog,
      reviewDialog,
      defaultProfilePic,
      reviewForm,
      reviewModel,
      reviewRule,
      submittingReview,
      submitReview,
      productLicense,
      isAbleToReview,
      isShowButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.back-button {
  font-family: Montserrat;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  padding-bottom: 30px;
}

.transaction-event {
  font-weight: bold;

  &.current {
    color: #31ac62;
  }
}

// .transaction-event-timeline {
//   .el-timeline-item:first-child {
//     --el-timeline-node-color: #31ac62;
//   }
// }
</style>

