<template>
  <div style="padding: 0 20px;" v-if="!gettingProfile">
    <ProductUploadForm
      :onSubmit="formSubmit"
      :resetForm="resetForm"
      :submitingForm="submitingForm"
      :defaultFormData="defaultFormData"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { getUrlParams } from '@/helpers';
import productServices from '@/services/product-service';
import profileServices from '@/services/profile-service';
import fileUploadServices from '@/services/file-upload-service';
import ProductUploadForm from '@/components/Product/ProductUploadForm.vue';

export default {
  name: 'Listings',
  setup() {
    const store = useStore();
    const profile = ref(null);
    const gettingProfile = ref(true);
    const submitingForm = ref(false);
    const router = useRouter();
    const editProductId = getUrlParams('editid');
    const defaultFormData = ref(null);
    let editProduct = null;

    onMounted(async () => {
      profile.value = await profileServices.getProfilebyProfileId(store.state.auth.loginProfile.profileId);

      // prevent user come into this page if user doesnt have payout info
      if (!profile.value.payoutInfo) {
        router.push('/profile/settings');
        ElMessage.error({
          showClose: true,
          message: 'Please complete your preferred payment method before listing.',
        });
      }

      if (editProductId) {
        editProduct = await productServices.getProductById(editProductId);
        // return if not owner
        if (editProduct.createdByUserId !== profile.value.userId) {
          router.go(-1);
        }

        defaultFormData.value = {
          name: editProduct.name,
          salesType: editProduct.salesType,
          listingPrice: editProduct.listingPrice.toFixed(2),
          shippingFee: editProduct.shippingFee.toFixed(2),
          productImagesFiles: editProduct.images.map((x) => ({
            imageUrl: x.productImageUrl,
          })),
          characters: editProduct.characters.map((x) => x.characterId),
          seriesId: editProduct.series.seriesId,
          scale: editProduct.scale,
          license: editProduct.series.license.licenseId,
          size: editProduct.size,
          serialNumber: editProduct.serialNumber,
          weight: editProduct.weight,
          distributor: editProduct.distributor,
          description: editProduct.description,
        };

        if (editProduct.salesType === 'Auction') {
          defaultFormData.value.minAuctionIncrement = editProduct.minAuctionIncrement;
          defaultFormData.value.auctionEndDate = editProduct.auctionEndDate;
        }
      }
      gettingProfile.value = false;
    });

    const createNewListing = (form) => {
      form.validate(async (valid) => {
        if (valid) {
          submitingForm.value = true;
          const productImages = [];

          // clone new imae file
          // upload first image
          // remove first image from the list
          // and upload async
          const imageFile = [...form.model.productImagesFiles];
          imageFile.splice(0, 1);
          if (form.model.productImagesFiles[0].imageFile) {
            productImages.push(process.env.VUE_APP_FILE_DOMAIN + (await fileUploadServices.uploadFile(form.model.productImagesFiles[0].imageFile)));
          } else {
            productImages.push(form.model.productImagesFiles[0].imageUrl);
          }

          await Promise.all(
            imageFile.map(async (file) => {
              if (file.imageFile) {
                const fileUrl = await fileUploadServices.uploadFile(file.imageFile);
                productImages.push(process.env.VUE_APP_FILE_DOMAIN + fileUrl);
              }
            }),
          );

          const productDetails = [
            {
              ...form.model,
              includeWith: 'null',
              images: productImages,
              characters: form.model.characters,
            },
          ];

          await productServices.createProduct(productDetails).then(
            () => {
              ElMessage({
                showClose: true,
                message: 'Upload product successfully',
                type: 'success',
              });
              form.resetFields();
              router.push('/profile/listings');
            },
            (error) => {
              ElMessage({
                showClose: true,
                message: error,
                type: 'error',
              });
            },
          );
          submitingForm.value = false;
        }
      });
    };

    const editListing = (form) => {
      form.validate(async (valid) => {
        if (valid) {
          submitingForm.value = true;
          const productImages = [];
          const imageFile = [];

          form.model.productImagesFiles.forEach((x) => {
            if (x.imageFile) {
              imageFile.push(x);
            } else {
              productImages.push(x.imageUrl);
            }
          });

          if (productImages.length === 0) {
            imageFile.splice(0, 1);
            if (form.model.productImagesFiles[0].imageFile) {
              productImages.push(process.env.VUE_APP_FILE_DOMAIN + (await fileUploadServices.uploadFile(form.model.productImagesFiles[0].imageFile)));
            } else {
              productImages.push(form.model.productImagesFiles[0].imageUrl);
            }
          }

          await Promise.all(
            imageFile.map(async (file) => {
              if (file.imageFile) {
                const fileUrl = await fileUploadServices.uploadFile(file.imageFile);
                productImages.push(process.env.VUE_APP_FILE_DOMAIN + fileUrl);
              }
            }),
          );

          const productDetail = {
            ...form.model,
            includeWith: 'null',
            images: productImages,
            characters: form.model.characters,
          };
          await productServices.updateProduct(editProduct.productId, productDetail).then(
            () => {
              ElMessage({
                showClose: true,
                message: 'Update product successfully',
                type: 'success',
              });
              router.push(`/product/${editProduct.productId}`);
            },
            (error) => {
              ElMessage({
                showClose: true,
                message: error,
                type: 'error',
              });
            },
          );
          submitingForm.value = false;
        }
      });
    };

    const formSubmit = async (form) => {
      if (!editProductId) {
        createNewListing(form);
      } else {
        editListing(form);
      }
    };

    const resetForm = (form) => {
      form.resetFields();
    };

    return {
      profile,
      gettingProfile,
      formSubmit,
      resetForm,
      submitingForm,
      defaultFormData,
    };
  },
  components: {
    ProductUploadForm,
  },
};
</script>
